.c3-line {
  stroke-width: 2.6px !important;
}

.c3-axis-y text {
  font-weight: bold;
}

.c3-axis-x .tick line {
  display: none;
}

.c3-tooltip {
  color: black;
}

/* pt */
.c3-shapes.c3-shapes-Climatizador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Ventilador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Swing.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Dreno.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Drenagem.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Degelo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Eco.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Auto.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Exaustor.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Desumidificador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Umidificador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Motor.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Compressor.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Aquecimento.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Bomba.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Iluminação.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Aquecimento-Auxiliar.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Timer-Cíclico.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Sensor-de-nível.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Atraso-Ventiladores.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Rele-Agitador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Agenda.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Filtro.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Apoio.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Rele-Agitador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Rolagem-Cíclica.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Rele-Motor.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Refrigeração-1.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Refrigeração-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Aquecimento-1.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Aquecimento-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Rele-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-OUT2--Triac-Ativo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Bluetooth-ativo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Ponto-de-Acesso-ativo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-SD-Card-ativo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Memoria-ativo.c3-lines {
  opacity: 0;
}

/* en */
.c3-shapes.c3-shapes-Heatino.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Pump.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Dehumidifier.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Humidifier.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Auxiliary-Heating.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-OUT2--Active-Triac.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-OUT2--Active-Relay.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Active-Program.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Active-Auto-Tuning.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Active-Heating-Mode.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Active-Cooling-Mode.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Defrost.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Fans.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Lighting.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Drainage.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Delay-Fans.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Schedule.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Filter.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Support.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Fan.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Agitator-Relay.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Cyclic-rolling.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Heating.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Heating-1.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Heating-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Refrigeration-1.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Refrigeration-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Relay.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Relay-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Extractor-hood.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Drain.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Cyclic-Timer.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Air-conditioner.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Motor-Relay.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Level-sensor.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Bluetooth-active.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Access-Point-active.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-SD-Card-active.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Memory-active.c3-lines {
  opacity: 0;
}

/* es */
.c3-shapes.c3-shapes-Compresor.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Calefacción.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Deshumidificador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Humidificador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Calefacción-auxiliar.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-OUT2--Triac-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-OUT1--Relé-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Programa-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Sintonización-automática-activa.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Modo-de-calefacción-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Modo-de-enfriamiento-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Deshielo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Ventiladores.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Iluminación.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Drenaje.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Retraso-del-ventilador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Cronograma.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Filtrar.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Apoyo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Relé.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Económico.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Relé-agitador.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Desplazamiento-cíclico.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Relé-de-motor.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Calefacción-1.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Calefacción-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Refrigeración.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Refrigeración-1.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Refrigeración-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Relé-2.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Campana-extractora.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Drenar.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Temporizador-cíclico.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Balancearse.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Aire-acondicionado.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Bluetooth-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Punto-de-acceso-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-SD-Card-activo.c3-lines {
  opacity: 0;
}
.c3-shapes.c3-shapes-Memoria-activo.c3-lines {
  opacity: 0;
}
